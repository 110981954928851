.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: $link-color;
  text-decoration: $link-decoration;
  font-weight: inherit;
  border: 0;
  outline: 0;
}
.link-button:hover,
.link-button:focus {
  border: 0;
  outline: 0;
}

.link-button:hover {
  color: $link-hover-color;
  text-decoration: $link-hover-decoration;
}
