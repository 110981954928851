.table-border-top-0 :first-child tr:first-child {
  td,
  th {
    border-top: none;
  }
}

.table-hover {
  td, th {
    cursor: default;
  }
}
