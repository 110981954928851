@import "variables";
@import "~bootstrap/scss/mixins";

#large-xs-logo,
#large-xs-logo svg {
  max-height: 100px;
  fill: #8b9972;
}

tfoot {
  border-top: 4px double $table-border-color;
}

.table-tbody-groups {
  &.table-hover tbody tr:hover {
    background-color: initial;
  }

  &.table-hover tbody {
    @include hover {
      background-color: $table-hover-bg;
    }
  }

  tbody {
    + tbody {
      border-top: none;
    }

    tr td {
      border-top: none;
    }

    tr:first-child td {
      border-top: $table-border-width solid $table-border-color;
    }
  }
}

.navbar-nav > .divider {
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  margin-top: map-get($spacers, 2);
  margin-bottom: map-get($spacers, 2);
}

:not(:hover) {
  > .d-inline-parent-hover-0 {
    display: inline;
  }
  > .d-inline-parent-hover-1 {
    display: none;
  }
}
:hover {
  > .d-inline-parent-hover-0 {
    display: none;
  }
  > .d-inline-parent-hover-1 {
    display: inline;
  }
}

/*
  0 = 0 = black
  1 = 5676533 = yellow
  2 = 5026082 = green
  3 = 15709952 = blue
  4 = 4194432 = purple - ish
 */
.colour-0 { color: #000000; }
.colour-1 { color: #F59D56; }
.colour-2 { color: #22B14C; }
.colour-3 { color: #00B7EF; }
.colour-4 { color: #800040; }
