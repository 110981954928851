.list {
  position: relative;
}

.list-filter {
  margin-top: -1px;
  position: sticky;
  background: $gray-100;
  padding: map-get($spacers, 3);
  border-bottom: $border-width solid $border-color;

  @include media-breakpoint-up(sm) {
    top: -1px;
    margin-bottom: map-get($spacers, 4);
  }
  @include media-breakpoint-down(xs) {
    top: 54px;
  }
}
