@import "../../styles/variables";
// @import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$footer-height: 64px;

.correspondence-designer {
  position: relative;
  height: 100%;
  .correspondence-designer-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: $footer-height;
    overflow-y: auto;
    // padding: map-get($spacers, 3);
    // overf
  }
}
.correspondence-designer-actionbar {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: $footer-height;
  background: $gray-100;
  // color: #fff;
  padding: map-get($spacers, 2) map-get($spacers, 3) map-get($spacers, 2)
    map-get($spacers, 3);
  border-top: $border-width solid $border-color;
}

.email-recipient {
  white-space: nowrap;
}
