@import "../../styles/variables";
// @import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$header-height: 56px;
// $footer-height: 56px;

.fullscreen-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  // border: 10px solid rgba(0, 0, 0, 0.5);
  // overflow-y: auto;
}
.fullscreen-modal-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: $header-height;
  background: $gray-100;
  // color: #fff;
  padding: 0 0 0 map-get($spacers, 3);
  border-bottom: $border-width solid $border-color;
  h4 {
    align-self: center;
    margin: 0;
  }
}
.fullscreen-modal-header-close {
  padding: 0 map-get($spacers, 3);
}

.fullscreen-modal-content {
  position: absolute;
  left: 0;
  right: 0;
  top: $header-height;
  bottom: 0;
  &.scroll {
    overflow-y: auto;
    // margin-bottom: $footer-height;
  }
}

// .fullscreen-modal-footer {
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   height: $footer-height;
//   background: $gray-100;
//   // color: #fff;
//   padding: 0 0 0 map-get($spacers, 3);
//   border-top: $border-width solid $border-color;
// }
