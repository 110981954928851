@import "../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.trip-density {

  .nav-link {
    cursor: pointer;
  }

  table {
    @include media-breakpoint-down(sm) {
      display: block;
      overflow: auto;
    }

    td {
      a {
        text-decoration: none;
        color: $body-color;
        display: inline-block;
      }

      > .extra-details {
        display: none;
        position: absolute;
        font-size: small;
        padding: 10px 15px;
        background-color: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        text-align: left;

        dl {
          margin-bottom: 0;
          dt {
            display: inline;

            &:after {
              content: ": ";
            }
          }
          dd {
            display: inline;

            &:after {
              content: "\A";
              white-space: pre;
            }
          }
        }
      }

      &:hover {
        background-color: $gray-100;

        > .extra-details {
          display: block;
        }
      }
    }
  }
}
