@import "../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

#enquiries {
  table {
    @include media-breakpoint-down(lg) {
      display: block;
      overflow: auto;
    }
  }
}
