@import "../../styles/variables";
@import "~bootstrap/scss/mixins";

.trip-table {
  td {
    cursor: pointer;
  }

  .no-trip td {
    cursor: not-allowed;
  }
}