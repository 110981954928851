@import "../../styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

@include media-breakpoint-down(sm) {
  .filter-bar {
    flex-direction: column;

    > div {
      margin-bottom: 5px;
    }
  }
}

.large-dropdown {
  .dropdown-menu {
    max-height: 65vh;
    max-width: 90vw;
    overflow-y: auto;
  }

  .dropdown-toggle {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}